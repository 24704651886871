/* @format */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import useLoginContext from "@sa/react-login/useLoginContext";

import { navigate } from "gatsby";
import { Button } from "gatsby-theme-material-ui";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import UserIcon from "@material-ui/icons/Person";

export default function UserIndicator({ color, hideLogin, ...other }) {
    const loginContext = useLoginContext();
    const [open, setOpen] = useState(null);

    const userInfo = useMemo(() => {
        if (!loginContext || loginContext.isAnonymous()) {
            return null;
        } else {
            return loginContext.getUserInfo();
        }
    }, [loginContext]);
    if (!userInfo) {
        if (!hideLogin) {
            return (
                <span {...other}>
                    <Button color={color || "inherit"} href="/user/login">
                        Login
                    </Button>
                </span>
            );
        } else {
            return null;
        }
    } else {
        return (
            <span {...other}>
                <Tooltip title={`Angemeldet als ${userInfo.login}`}>
                    <IconButton
                        aria-controls="user-menu"
                        aria-haspopup="true"
                        color={color || "inherit"}
                        onClick={(ev) => {
                            setOpen(ev.currentTarget);
                        }}
                    >
                        <UserIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="user-menu"
                    anchorEl={open}
                    keepMounted
                    open={Boolean(open)}
                    onClose={() => setOpen(null)}
                >
                    <MenuItem
                        onClick={async () => {
                            setOpen(false);
                            if (loginContext) {
                                try {
                                    await loginContext.loginService.logout();
                                    navigate("/");
                                } catch (err) {
                                    console.log("AUTSCH! NO LOGOUT!!!");
                                }
                            }
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </span>
        );
    }
}

UserIndicator.propTypes = {
    color: PropTypes.string,
    hideLogin: PropTypes.bool
};
UserIndicator.defaultProps = {
    color: null,
    hideLogin: false
};
