/* @format */
import _ from "lodash-core";

import { makeStyles } from "@material-ui/core/styles";

export const commonStyles = (theme) => ({
    "@global": {
        "*": {
            margin: 0,
            padding: 0
        },
        body: {
            backgroundColor: theme.palette.background.default
        },
        main: {
            marginTop: theme.spacing(2)
        }
    },
    content: {
        maxWidth: 1280,
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    block: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    blockContent: {
        padding: theme.spacing(1)
    },
    header: {
        marginBlockStart: 0,
        marginBlockEnd: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius
    },
    frame: {
        display: "flex",
        flexDirection: "column"
    },
    frameMain: {
        flexGrow: 1
    },
    fullHeight: {
        height: "100%"
    },
    btnRight: {
        float: "right"
    },
    bottomSpace: {
        marginBottom: theme.spacing(2)
    }
});

export const useCommonStyles = makeStyles(commonStyles);

export function makeCommonStyles(styles) {
    const useStyles = makeStyles(styles);
    return () => {
        const s1 = useCommonStyles();
        const s2 = useStyles();
        return _.assign({}, s1, s2);
    };
}
