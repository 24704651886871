/* @format */
import React from "react";
import PropType from "prop-types";

import { IconButton } from "gatsby-theme-material-ui";

import { makeCommonStyles } from "../styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
//import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import UserIndicator from "./UserIndicator";

const useStyles = makeCommonStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    }
}));

export default function ToolbarHeader({ title }) {
    const classes = useStyles();
    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    href="/"
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
                <UserIndicator />
            </Toolbar>
        </AppBar>
    );
}

ToolbarHeader.propTypes = {
    title: PropType.node.isRequired
};
